import Button from "../items/Button";
import st from "../styles/games/GameUi.module.scss";
import useLanguage from "../hooks/useLanguage";
import { useUserSession } from "../hooks/useUserSession";
export default function FairButton({
  classItem,
  newFair = "fair",
  additionalQuery = {}
}) {
  const L = useLanguage("FairButton");
  const {
    userData
  } = useUserSession();
  return <div className={`${st["game-settings-btn"]} ${classItem ? st[classItem] : null} `} data-sentry-component="FairButton" data-sentry-source-file="FairButton.jsx">
      <Button buttonType={"link"} query={{
      modal: "fairplay",
      tab: userData ? "seeds" : "verify",
      ...additionalQuery
    }} classType={[newFair, "icon-left"]} text={L("text")} icon={<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
            <g clipPath="url(#clip0_10751_28269)">
              <path d="M13.554 6.24468L13.0034 2.66602C9.00336 2.66602 7.00336 0.666016 7.00336 0.666016C7.00336 0.666016 5.00336 2.66602 1.00336 2.66602L0.452692 6.24468C0.223055 7.73534 0.458626 9.26054 1.1273 10.6125C1.79598 11.9644 2.86524 13.0772 4.18936 13.7993L7.00336 15.3327L9.81736 13.7993C11.1415 13.0772 12.2107 11.9644 12.8794 10.6125C13.5481 9.26054 13.7837 7.73534 13.554 6.24468ZM10.1414 6.47068L6.14136 10.4707C6.01634 10.5957 5.8468 10.6659 5.67003 10.6659C5.49325 10.6659 5.32371 10.5957 5.19869 10.4707L3.86536 9.13735C3.80169 9.07585 3.7509 9.00229 3.71596 8.92095C3.68102 8.83962 3.66263 8.75214 3.66186 8.66362C3.66109 8.5751 3.67796 8.48731 3.71148 8.40538C3.745 8.32345 3.7945 8.24901 3.8571 8.18642C3.91969 8.12382 3.99413 8.07432 4.07606 8.0408C4.15799 8.00728 4.24577 7.99041 4.33429 7.99118C4.42281 7.99195 4.51029 8.01034 4.59163 8.04528C4.67297 8.08022 4.74653 8.13101 4.80803 8.19468L5.67003 9.05668L9.19869 5.52802C9.32443 5.40658 9.49283 5.33938 9.66763 5.3409C9.84243 5.34242 10.0096 5.41253 10.1332 5.53614C10.2568 5.65974 10.327 5.82695 10.3285 6.00175C10.33 6.17655 10.2628 6.34495 10.1414 6.47068Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_10751_28269">
                <rect width="14" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>} active={false} data-sentry-element="Button" data-sentry-source-file="FairButton.jsx" />
    </div>;
}