import SoundOff from "../assets/icons/general/SoundOff";
import SoundOn from "../assets/icons/general/SoundOn";
import st from "../styles/items/GlobalSoundManager.module.scss";
import StorageService from "../lib/services/Storage.Service";
import { memo, useState, useEffect } from "react";
function GlobalSoundManager() {
  const [isMuted, setIsMuted] = useState(false);
  const toggleMute = () => {
    if (isMuted) {
      //   Howler.volume(0); // Mute all sounds
      setIsMuted(false);
      //set localStorage values
      StorageService.setLocalStorageValue("isMuted", false);
    } else {
      //   Howler.volume(1); // Set volume back to normal (100%)
      setIsMuted(true);
      StorageService.setLocalStorageValue("isMuted", true);
    }
  };
  useEffect(() => {
    const isMuted = StorageService.getLocalStorageValue("isMuted");
    if (isMuted) {
      setIsMuted(true);
    }
  }, []);
  return <button className={st["sound-setting"]} onClick={toggleMute} data-sentry-component="GlobalSoundManager" data-sentry-source-file="GlobalSoundManager.jsx">
      {isMuted ? <SoundOff /> : <SoundOn />}
    </button>;
}
export default memo(GlobalSoundManager);